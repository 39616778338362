@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

Image {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

body {
  background-image: linear-gradient(to bottom, rgba(242,149,30,0), rgb(255,174,0,0.2));
  /* overflow: hidden;
  height: inherit; */
}

.balance-font {
  font-family: 'Catamaran', sans-serif;
  color: #5a7e88;
}

.accrue-notice {
  text-align: center;
  font-family: 'Catamaran', sans-serif;
  color: #5a7e88;
  font-size: 0.8em;
}

.total-btn {
  cursor: default;
  border-color: #F2951E;
  font-family: 'Catamaran', sans-serif;
  background-color: white;
  color: black;
  width: 80px;
  text-decoration: none;
  font-size: 0.7em;
}

.wallet-btn {
  width: 80%;
}

.btn-lg {
  background-color: #08233D;
  border-color: #08233D;
}

.dep-btn {
  background-color: white;
  border-color: #08233D;
  color: #08233D;
}

.center-text {
  text-align: center;
}

.addr-label {
  font-family: 'Catamaran', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
  text-overflow: ellipsis;
  cursor: pointer;
}

.balance-label {
  text-align: center;
}

.mMask {
  cursor: pointer;
}

.earned-balance-label {
  text-align: center; 
}

.staked-balance-label {
  text-align: center;  
}

.estimated-label {
  text-align: center;
}

.solid {
  border-top: 2px solid #F2951E;
}

.center-icon {
  width: 20%;
}

.vstack {
  width: auto;
}

.main-splash {
  margin-top: 3%;
}

.splash-icon {
  width: 22%;
}

.splash-text {
  font-family: 'Catamaran', sans-serif;
  font-size: 2em;
  text-align: center;
}

.splash-warn {
  text-align: center;
  font-family: 'Catamaran', sans-serif;
  color: #5a7e88;
  font-size: 0.6em; 
}

.splash-logo {
  width: 60%;
}

.main-logo {
  cursor: pointer;
}

.main {
  background-color: white;
  border-radius: 12px;
  margin-top: 3%;
  width: 46%;
  height: 94%;
}

.main-footer {
  width: 36%;  
}

/* wallet page */
.main-addr-label {
  font-family: 'Catamaran', sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  white-space: nowrap;
  overflow: hidden;
  width: 310px;
  text-overflow: ellipsis; 
}

.wallet-txt {
  font-family: 'Catamaran', sans-serif;
  color: #5a7e88;
}

.scan-btn {
  width: 60%;
  font-family: 'Catamaran', sans-serif;
}
/* END */

.logo {
  color: black;
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 603px) {
  .splash-logo {
    width: 100%;
  }

  .total-btn { 
    margin-left: 18%;
  }

  .main {
    background-color: white;
    border-radius: 12px;
    margin-top: 3%;
    width: 90%;
  }

  .addr-label {
    width: 50px;
    font-size: 0.9em;
    padding-top: 10%;
  }
  .total-btn {
    font-size: 0.8em;
  }
  .center-icon {
    width: 40%;
  }
  .main-footer {
    width: 80%;  
  }
  .main-logo {
    width: 170%;
  }
  .mMask {
    margin-left: -40%;
  }
}
